<template>
    <div class="user-main">
        <div class="title">
            <span>我要写游记</span>
            <el-divider></el-divider>
            <div class="hide">
            <div class="biaodan">
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="form"
                    label-width="100px"
                >
                    <el-form-item label="标题：" prop="theTitle">
                        <el-input v-model="form.theTitle"></el-input>
                    </el-form-item>
					<el-form-item label="简介:" prop="theInfo">
						<el-input
							type="textarea"
							:rows="3"
							placeholder="请输入简介"
							v-model="form.theInfo">
						</el-input>
					</el-form-item>
                    <el-form-item label="图片:" prop="theLocal">
                        <Pictureupload
                            :picimg="form.theLocal"
                            @change="picChange"
                        />
                    </el-form-item>
                    <el-form-item label="内容详情:" prop="theCont">
                        <wangEnduit v-model="form.theCont" />
                    </el-form-item>
                    <div class="but">
                        <el-button type="primary" @click="onSubmitput('form')"
                            >确认发布</el-button
                        >
                    </div>
                </el-form>
            </div>
            </div>
            <div class="dispy">
            <div class="biaodan">
                <el-form
                    :model="form"
                    :rules="rules"
                    ref="form"
                    label-width="100px"
                    :label-position="labelPosition"
                >
                    <el-form-item label="标题：" prop="theTitle">
                        <el-input v-model="form.theTitle"></el-input>
                    </el-form-item>
					<el-form-item label="简介:" prop="theInfo">
						<el-input
							type="textarea"
							:rows="3"
							placeholder="请输入简介"
							v-model="form.theInfo">
						</el-input>
					</el-form-item>
                    <el-form-item label="图片:" prop="theLocal">
                        <Pictureupload
                            :picimg="form.theLocal"
                            @change="picChange"
                        />
                    </el-form-item>
                    <el-form-item label="内容详情:" prop="theCont">
                        <wangEnduit v-model="form.theCont" />
                    </el-form-item>
                    <div class="but">
                        <el-button type="primary" @click="onSubmitput('form')"
                            >确认发布</el-button
                        >
                    </div>
                </el-form>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pictureupload from "@/components/user/Pictureupload";
import wangEnduit from "@/components/wangEnduit";
import { getAction,postAction,putAction } from '@/api/manage.js'
export default {
    props: ["picimg"],
    components: {
        Pictureupload,
        wangEnduit,
    },
    mounted() {
        let id = this.$route.query.id
        if(id){
            this.editHandler(id)
        }
    },
    data() {
        return {
            labelPosition: 'top',
            form: {
                theLocal: "",
                theTitle: "",
                theCont: "",
            },
            rules: {
                theTitle: [
                    {
                        required: true,
                        message: "请输入标题",
                        trigger: "change",
                    },
                ],
                theLocal: [
                    {
                        required: true,
                        message: "请上传图片",
                        trigger: "change",
                    },
				],
				theInfo: [
					{
                        required: true,
                        message: "请输入简介",
                        trigger: "change",
                    },
				],
                theCont: [
                    {
                        required: true,
                        message: "请输入内容",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    methods: {
        onSubmitput(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let id = this.$route.query.id
                    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
                    let data = Object.assign(this.form, { uid: userInfo.id });
                    if(id){
                        // 修改
                        data.id = id
                        putAction('/note/tourNote/edit',data).then(res => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.$router.push('mtravels')
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }else{
                        // 添加
                        postAction("/note/tourNote/add", data).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.$router.push('mtravels')
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        editHandler(id){
            getAction('/note/tourNote/queryById',{
                id: id
            }).then(res => {
                if(res.success){
                    this.form = res.result
                }
            })
        },
        picChange(img) {
            this.form.theLocal = img;
        },
    },
};
</script>
<style lang="scss" scoped>
.user-main {
    padding: 25px;
    .title {
        font-size: 16px;
        color: #999999;
    }
    .el-divider--horizontal {
        margin: 13px 0 38px;
    }
    .but {
        margin-left: 100px;
        margin-top: 38px;
        .el-button--primary {
            width: 212px;
            height: 44px;
            background-color: #6EB400;
            border-radius: 3px;
            border-color: #6EB400;
        }
    }
}
</style>
